import React, { useState } from "react";

const SetsProductCardComponent = ({
  product,
  selectedProducts,
  handleCheckboxChange,
  calculateItemsPrice,
}) => {
  const [quantity, setQuantity] = useState(1);
  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
    selectedProducts.forEach((selectedProduct) => {
      if (selectedProduct._id === product._id) {
        selectedProduct.purchasedQuantity = e.target.value;
      }
    });
    calculateItemsPrice();
  };
  return (
    <div
      key={product._id}
      className={`min-w-[200px] rounded-xl p-2 ${
        selectedProducts.some((item) => item._id === product._id) &&
        "bg-blue-200"
      }`}
    >
      <label htmlFor={product._id}>
        <img
          src={product.img}
          alt="product"
          className="w-[200px] h-fit rounded-xl drop-shadow-sm"
        />
      </label>
      <p className="mt-2">{product.price} دج</p>
      <input
        id={product._id}
        type="checkbox"
        checked={selectedProducts.some((item) => item._id === product._id)}
        onChange={(e) => handleCheckboxChange(e, product)}
        className="mt-2"
      />
      <div>
        <input
          className="p-4 w-16 h-12 "
          type="number"
          name="quantity"
          min="1"
          value={quantity}
          onChange={handleQuantityChange}
        />
        <h4 className="">{product.name}</h4>
      </div>
    </div>
  );
};

export default SetsProductCardComponent;
